import Form from '@/library/Form';
    const NepaliDate = require('nepali-date');
    const nd = new NepaliDate();

export default {
    form: new Form({
            general_type: '',
            fiscal_year_id:'1',
            narration: '',
            payment_mode: '',
            voucher_no:'',
            jt_id: '', // for validation check
            // serial_no: ',

            files: '',
            entry_date: nd.format('YYYY-MM-DD'),
            details: [{
                ledger_head_id: '',
                ledger_head_name: '',
                total_amount: 0,
                tax: 0,
                net_amount: 0,
                opening_balance: 0
            }],
            ledger:[],
            journal:{},
        },
        '/api/payment-receipt'
    ),

    accountGroupForm: new Form({
        name: '',
        description: '',
        company_id: '',
        parent_id: ''
    }, '/api/account-head'),
    accountGroupFormDialog: false,
    searchAG: null,

    searchA: null,
    entriesA: [],

    payment_mode_type: {
        mode: 'cash',
        cheque_no: '',
        cheque_date: '',
        journal_type_id: '',
        ledger_head_id: '',
        bank_ledger_head_id: '',
        final_amount: 0
    },
    pr_type: [],


    ledgerForm: new Form({
        name: '',
        description: '',
        account_head_id: '',
        opening_balance:0,
        drcr:'dr',
    }, '/api/ledger-head'),

    ledgerHeads: [], /** Ledger Heads **/

    journalTypes: [], /** Journal Types **/

    accountHeads: [], /** Account Heads **/

    banks: [], /** Banks **/

    cashes:[], /** Cashes**/
    manualSetting : false,

    drcrList: [
        {value: 'dr', text: 'DR'},
        {value: 'cr', text: 'CR'}
    ],
    paymentMode: [],
    files: null,
    automaticVoucherNo : '',
    searchKey: null,
    itemsLedger: [],
}
